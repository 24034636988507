import { Fallback } from "@components/Fallback";
import { TranslationsLoader } from "@components/TranslationsLoader";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";
import { trackPage, trackPageOnLoad } from "@services/analytics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import { Poppins } from "next/font/google";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import * as React from "react";
import "react-phone-input-2/lib/style.css";
import "../global.css";
import { NextPage } from "next";
import { env } from "env/client";
import "../markdown.css";

//import the Poppins font from Google Fonts
const poppins = Poppins({
  weight: ["400", "500", "600", "700"],
  style: "normal",
  subsets: ["latin"],
  display: "swap",
});

// Create a client-side GrowthBook instance
const gb = new GrowthBook({
  apiHost: env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  // Enable easier debugging of feature flags during development
  enableDevMode: true,
});

const queryClient = new QueryClient();

const fallback = <Fallback />;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  const { locale } = router;

  // Use the custom layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: React.ReactElement) => page);

  React.useEffect(() => {
    const dir = locale?.includes("ar") ? "rtl" : "ltr";
    document && document.querySelector("body")?.setAttribute("dir", dir);
  }, [locale]);

  // On Load
  React.useEffect(() => {
    trackPageOnLoad();
    Router.events.on("routeChangeComplete", trackPage);
    // Load features from the GrowthBook API and keep them up-to-date
    gb.loadFeatures({ autoRefresh: true });

    // remove event listener on unmount
    return () => {
      Router.events.off("routeChangeComplete", trackPage);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${poppins.style.fontFamily};
        }
      `}</style>
      <Script
        id="segment-script"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="6JhqRnhLCcMenKt34Lk5MvS38NFzEuSj";;analytics.SNIPPET_VERSION="4.15.3";
          }}();
          analytics.load("${env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY}");`,
        }}
      />
      <GrowthBookProvider growthbook={gb}>
        <SessionProvider session={pageProps.session}>
          <QueryClientProvider client={queryClient}>
            <TranslationsLoader>
              <Sentry.ErrorBoundary fallback={fallback} showDialog>
                {getLayout(<Component {...pageProps} />)}
              </Sentry.ErrorBoundary>
            </TranslationsLoader>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </SessionProvider>
      </GrowthBookProvider>
    </>
  );
};

export default App;
