import { useTranslations } from "@services/strings";

export const Fallback = () => {
  const { t } = useTranslations();

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="text-2xl text-center">{t("common.error.generic")}</div>
    </div>
  );
};
