import { parseNumber } from "@brightspace-ui/intl/lib/number.js";
import { Path } from "dot-path-value";
import merge from "lodash.merge";
import Polyglot from "node-polyglot";
import React, {
  ComponentType,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import replace from "react-string-replace";
import enUS from "../locales/en-US.json";

export type EnUs = typeof enUS;

/** The dotted path to a string in a locale's JSON file. */
export type TranslationKey = Path<EnUs>;

/** A map of {@link TranslationKey}s to their translated strings. */
export type Translations = Record<TranslationKey, string>;

type Strings = { [property: string]: Strings | string };

/** Recursive `Object.assign` */
// const merge = (destination: Strings, source: Strings): Strings => {
//   Object.entries(source).forEach(([key, value]) => {
//     if (typeof value === "string") {
//       destination[key] = value;
//     } else {
//       merge(destination[key] as Strings, value);
//     }
//   });

//   return destination;
// };

/**
 * Flatten a nested JSON object to an object with depth = 1 and properties which
 * are the dotted paths of the input object.
 */
const flatten = (
  data: Strings,
  ancestors: string[] = []
): Record<string, string> => {
  const output: Record<string, string> = {};

  Object.entries(data).forEach(([key, value]) => {
    const keys = ancestors.concat([key as string]);

    if (typeof value === "string") {
      output[keys.join(".")] = value;
    } else {
      Object.assign(output, flatten(value, keys));
    }
  });

  return output;
};

export const LOCALE_AR_EG = "ar-EG" as const;
export const LOCALE_EN_EG = "en-EG" as const;
export const LOCALE_EN_US = "en-US" as const;

export const DEFAULT_LOCALE = LOCALE_AR_EG;

export const LOCALES = [LOCALE_AR_EG, LOCALE_EN_EG, LOCALE_EN_US] as const;

export type Locale = (typeof LOCALES)[number];

export const CURRENCY_EGP = "EGP" as const;
export const CURRENCY_USD = "USD" as const;

export const CURRENCIES = [CURRENCY_EGP, CURRENCY_USD];

export type Currency = (typeof CURRENCIES)[number];

export const LOCALE_CURRENCIES: Record<Locale, Currency> = {
  "ar-EG": CURRENCY_EGP,
  "en-EG": CURRENCY_EGP,
  "en-US": CURRENCY_USD,
};

export const CURRENCY_SYMBOL_AR_EGP = "EGP";
export const CURRENCY_SYMBOL_EN_EGP = "EGP";
export const CURRENCY_SYMBOL_EN_USD = "$";

export const CURRENCY_SYMBOLS = [
  CURRENCY_SYMBOL_AR_EGP,
  CURRENCY_SYMBOL_EN_EGP,
  CURRENCY_SYMBOL_EN_USD,
] as const;

export type CurrencySymbol = (typeof CURRENCY_SYMBOLS)[number];

export const LOCALE_CURRENCY_SYMBOLS: Record<Locale, CurrencySymbol> = {
  "ar-EG": CURRENCY_SYMBOL_AR_EGP,
  "en-EG": CURRENCY_SYMBOL_EN_EGP,
  "en-US": CURRENCY_SYMBOL_EN_USD,
};

const importLocale = (locale: Locale): Promise<{ default: Strings }> =>
  import(`../locales/${locale}.json`);

/** Get the {@link Translations} in the specified locale, or `en-EG` if not provided. */
export const loadStrings = async (
  locale: Locale | undefined = "en-EG"
): Promise<Translations> => {
  const { default: source } = await importLocale(locale);
  const strings = {};
  merge(strings, enUS, source);

  return flatten(strings) as Translations;
};

interface TranslationsContextValue {
  locale: Locale;
  polyglot: Polyglot;
}

const TranslationsContext = createContext<TranslationsContextValue | undefined>(
  undefined
);

interface TranslationsProviderProps {
  children?: React.ReactNode;
  locale: Locale;
  strings: Translations;
}

/** Provides a translation string lookup and interpolation function for the provided {@link Translations}. */
export const TranslationsProvider: FC<TranslationsProviderProps> = ({
  children,
  locale,
  strings,
}) => {
  const polyglot = useMemo(
    () =>
      new Polyglot({
        phrases: strings,
        interpolation: { prefix: "{{", suffix: "}}" },
      }),
    [strings]
  );

  return (
    <TranslationsContext.Provider value={{ locale, polyglot }}>
      {children}
    </TranslationsContext.Provider>
  );
};

type PolyglotTranslateFn = InstanceType<typeof Polyglot>["t"];

/**
 * A `node-polyglot` translate function accepting the path to a string and
 * optional interpolation values and returning the localized string.
 *
 * @param {TranslationKey} key The dotted path to the string to translate.
 * @param {Polyglot.InterpolationOptions} options An optional object containing
 * key-value pairs to interpolate into the string.
 * @example t("path.to.string", { user: "Name", value: "1" });
 */
export type TranslateFn = (
  key: TranslationKey,
  options?: Parameters<PolyglotTranslateFn>[1]
) => ReturnType<PolyglotTranslateFn>;

export interface TranslationsHookValue {
  t: TranslateFn;
  locale: Locale;
  currency: Currency;
  currencySymbol: CurrencySymbol;
  formatNumber: (value: number) => string;
  formatCurrency: (value: number) => string;
  formatPercent: (value: number) => string;
  parseNumber: typeof parseNumber;
}

/** Get a {@link TranslateFn} providing string lookup and interpolation for the current locale. */
export const useTranslations = (): TranslationsHookValue => {
  const context = useContext(TranslationsContext);

  if (!context) {
    throw new Error("Strings have not been loaded");
  }

  const { locale, polyglot } = context;
  const intlLocale: Locale = locale === "en-US" ? "en-US" : "en-EG";

  const numberFormatter = new Intl.NumberFormat(intlLocale);

  const currencyFormatter = new Intl.NumberFormat(intlLocale, {
    style: "currency",
    currency: LOCALE_CURRENCIES[locale],
    currencyDisplay: "code",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const percentFormatter = new Intl.NumberFormat(intlLocale, {
    style: "percent",
  });

  return {
    t: polyglot.t.bind(polyglot),
    locale,
    currency: LOCALE_CURRENCIES[locale],
    currencySymbol: LOCALE_CURRENCY_SYMBOLS[locale],
    formatNumber: numberFormatter.format.bind(numberFormatter),
    formatCurrency: currencyFormatter.format.bind(currencyFormatter),
    formatPercent: percentFormatter.format.bind(percentFormatter),
    parseNumber,
  };
};

/**
 * Interpolate React elements into a string.
 *
 * @param input The input string containing tokens to be replaced.
 *  E.g. `Text {{italics}}with{{/italics}} {{bold}}tokens{{/bold}}`
 * @param elements An object mapping tokens to their replacements.
 *  E.g. `{ bold: 'b', italics: 'i' }`
 * @returns A React element containing the interpolated string
 * @example
 * ```ts
 * const Custom: FC = ({ children }) => (
 *   <div className="example">
 *     {children}
 *   </div>
 * );
 *
 * const Example = () => {
 *   const { t } = useTranslations();
 *
 *   const input = t('path.to.string');
 *   const element = interpolate(input, {
 *     paragraph: 'p',
 *     custom: Custom
 *   });
 *
 *   return element;
 * };
 * ```
 */
export const interpolate = (
  input: string,
  elements: Record<
    string,
    ComponentType<{ children?: ReactNode }> | keyof JSX.IntrinsicElements
  >
): ReactElement => {
  const result = Object.entries(elements).reduce(
    (outerNodes, [token, Component]) =>
      // Replace both wrapped content and plain tokens,
      // e.g. `{{token}}content{{/token}}` and `{{token}}`,
      // giving the former precedence
      [new RegExp(`{{${token}}}(.+){{/${token}}}`), `{{${token}}}`].reduce(
        (innerNodes, pattern) =>
          replace(innerNodes, pattern, (children, index) => (
            <Component key={index}>{children}</Component>
          )) as ReactNode[],
        outerNodes
      ),
    [input] as ReactNode[]
  );
  return <>{result}</>;
};
