import { DEFAULT_LOCALE, Locale } from "@services/strings";
import pick from "just-pick";
import Router from "next/router";
import { BaseTrackingEvent } from "./types";

const queryKeys = ["cta-ref"] as const;
export type QueryKey = (typeof queryKeys)[number];

type ParsedParameters = Record<QueryKey, string>;

/**
 * Parse the query string and return an object with known query keys and values.
 */
const parseParams = (path: string): ParsedParameters => {
  const paramString = path.split("?")[1];
  const params = new URLSearchParams(paramString);
  const parsedParams = Object.fromEntries(params);

  // spreading the array to satisfy the type checker
  return pick(parsedParams, [...queryKeys]);
};

/**
 * - Parse the query string and return only known keys.
 * - Remove the query string and hash from the pathname.
 * - Replace the leading slash with "landing" if the pathname is "/".
 * - Remove the leading slash from the pathname.
 */
export const sanitizeName = (
  basePath: string,
  locale?: Locale,
): { path: string; parameters: ParsedParameters } => {
  const parameters = parseParams(basePath);

  let path: string = basePath;

  // remove locale from path
  if (locale) {
    path = path.replace(`${locale}`, "");
  }

  // remove leading slash, query string and hash
  path = path.replace(/^\/+/, "").split(/[?#]/)[0];

  // return "landing" if the path is "/"
  if (path === "") {
    path = "landing";
  }

  return { path, parameters };
};

export const trackPageOnLoad = () => {
  const locale = Router.locale || DEFAULT_LOCALE;
  const { path, parameters } = sanitizeName(Router.pathname);

  analytics.page(path, { locale, ...parameters });
};

/**
 * To be used with the `onRouteChangeComplete` event.
 * @param basePath: the pathname of the page including the locale if available
 */
export const trackPage = (basePath: string): void => {
  const locale = Router.locale || DEFAULT_LOCALE;
  const { path, parameters } = sanitizeName(basePath, locale as Locale);

  analytics.page(path, { locale, ...parameters });
};

/**
 * Adds a delay to the tracking event to ensure that the event is sent to the
 * analytics service before e.g. the user is redirected to another page.
 */
export const trackAndWait = async ({
  event,
  properties,
}: BaseTrackingEvent): Promise<void> => {
  analytics.track(event, properties);
  await new Promise((resolve) => setTimeout(resolve, 300));
};

/**
 * Tracks a client-side base event.
 */
export const track = (
  { event, properties }: BaseTrackingEvent,
  additionalProperties?: { [key: string]: unknown },
): void => {
  analytics.track(event, {
    ...additionalProperties,
    ...properties,
  });
};
