/** Client-side Firebase app and auth */
import { env } from "env/client";
import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const init = () => {
  try {
    return getApp();
  } catch {
    return initializeApp({
      apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
      projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    });
  }
};

export const app = init();
export const auth = getAuth(app);

auth.useDeviceLanguage();
