import { auth } from "@services/firebase";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import {
  LOCALES,
  LOCALE_EN_EG,
  Locale,
  Translations,
  TranslationsProvider,
  loadStrings,
} from "services/strings";

export const TranslationsLoader: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const [locale, setLocale] = useState<Locale | undefined>();
  const [strings, setStrings] = useState<undefined | Error | Translations>(
    undefined
  );

  useEffect(() => {
    const load = async () => {
      let _locale = router.locale as Locale | undefined;
      if (!_locale || !LOCALES.includes(_locale)) {
        _locale = LOCALE_EN_EG;
      }

      try {
        const _strings = await loadStrings(_locale);
        setLocale(_locale);
        setStrings(_strings);
        auth.languageCode = _locale.split("-")[0];
      } catch (error: unknown) {
        setStrings(error instanceof Error ? error : new Error(`${error}`));
      }
    };

    load();
  }, [router.locale]);

  if (!strings) {
    return null;
  } else if (strings instanceof Error) {
    return <>{`${strings}`}</>;
  } else {
    return (
      <TranslationsProvider locale={locale ?? LOCALE_EN_EG} strings={strings}>
        {children}
      </TranslationsProvider>
    );
  }
};
